import React, { Component, Fragment } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { productService } from '../services/product.service'

export default class ProductAddEditForm extends Component {


    constructor() {
        super()
        this.state = {

            formData: {
                name: ""
            },
            selectedRow: null,
            action: "add",
            actionButtonText: "Add Product",
            isLoading: false
        }
    }

    async componentDidUpdate(prevProps) {

        console.log(`Prev Propos ->`, prevProps);
        console.log(`Current Propos ->`, this.props);

        // const {selectedRow} = 

        if ((prevProps.action != this.props.action)) {

            this.handleLifeCyleLoad()
        }


    }

    handleLifeCyleLoad = async () => {
        let { action, selectedRow } = this.props

        if (selectedRow != null) {
            const productInfo = await productService.get(selectedRow.id)
            console.log(`Product info ->`, productInfo);

            selectedRow = { ...selectedRow, ...productInfo.data }
        }

        this.setState({ action, selectedRow })

        setTimeout(async () => {

            if (action == "edit") {
                this.handleFormPopulate()
            }

        }, 500);

    }

    async componentDidMount() {
        // const {formFields} = 
        this.handleLifeCyleLoad()
    }

    updateFormDataState = (key, value) => {
        const formData = this.state.formData
        formData[key] = value
        this.setState({ formData })
    }

    handleValueChange = (event) => {
        const { name, value } = event.target
        console.log(event.target);
        console.log(`${name} : ->`, value);
        this.updateFormDataState(name, value)
    }


    handleFormAction = async () => {
        const { formData, action, isLoading } = this.state
        const data = this.state.formData

        const { handleDone } = this.props

        this.setState({ isLoading: true })

        if (action == "add") {
            const productRequest = await productService.create(data)
            console.log(`LicensRequest ->`, productRequest);
        } else if (action == "edit") {
            const updateData = { ...formData }
            const updateRequest = await productService.updateProduct(updateData)
        }

        this.setState({ isLoading: false })

        // handleDone("refresh")

        // TODO: Close Modal
        console.log(this.state.formData);
    }


    handleFormPopulate = () => {

        let { formData, selectedRow } = this.state
        formData = { ...formData, ...selectedRow }
        console.log(`Updated new Form ->`, formData);
        const actionButtonText = "Edit Product"
        this.setState({ formData, actionButtonText })

    }

    render() {
        const { formData, actionButtonText, isLoading } = this.state
        const { name } = formData
        return (
            <Fragment>
                <form>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="">Name</label>
                            <input type="text" name="name" value={name} className="form-control" onChange={this.handleValueChange} placeholder="Name" />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        <div className="form-group d-flex justify-content-end col">
                            <button disabled={isLoading} type="button" className="btn btn-primary" onClick={this.handleFormAction}>
                                {isLoading ? <Spinner className="mr-2" animation="border" size="sm" /> : ""}

                                {actionButtonText}</button>
                        </div>
                    </div>

                </form >

            </Fragment >

        )
    }
}
