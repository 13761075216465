import HttpClient from "../../../core/commons/services/http-client.service";

export class LicenseUsageLogService extends HttpClient {
    constructor() {
        super("licenses");
    }

    async search(query, type, page = 1, perPage = 30) {
        const urlPath = `${this.resourceUrl}/search?query=${query}&type=${type}&page=${page}&perPage=${perPage}`;
        return await this.getRequest(urlPath);
    }

    async getLicenseUsageLogs(body) {
        this.cancelExistingAxiosToken();

        const { licenseId, page, perPage, sortColumn, sortOrder, query, groupBy } = body;
        const sortQuery = sortColumn && sortOrder ? `&sort_column=${sortColumn}&sort_order=${sortOrder}` : '';
        const searchQuery = query ? `&query=${query}` : '';
        const groupByQuery = groupBy ? `&group_by=${groupBy}` : '';

        const urlPath = `${this.resourceUrl}/activities?license_id=${licenseId}&page=${page}&perPage=${perPage}`
            + `${sortQuery}${searchQuery}${groupByQuery}`;
        return await this.getRequest(urlPath, this.axiosCancelToken?.token);
    }
}

export const licenseUsageLogService = new LicenseUsageLogService();
