import ProductsListPage from "./pages/product-list/ProductsListPage"


const BASE_PATH = "/products"

const PRODUCT_MANAGEMENT_ROUTES = [

    {
        path: BASE_PATH,
        exact: true,
        component: ProductsListPage,
        isSideBar: true,
        label: "Products"
    },
]


export default PRODUCT_MANAGEMENT_ROUTES