import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

import colorConfig from "../../../../config/color.config";
import { logoutUser } from "../../../commons/services/user.service";

export default function Header() {
  const basePath = "/licenses";
  const activeLinkStyle = {
    backgroundColor: colorConfig.darkBlue,
    color: 'white',
  }

  const logoutUserAfterConfirm = () => {
    logoutUser(true);
  };

  return (
    <Fragment>
      <header className="u-header">
        <div className="u-header-left">
          <NavLink to={`${basePath}`} className="u-header-logo">
            <img className="u-logo-desktop pad-logo" src="/assets/images/publisher-rocket-blue.png" width="175" alt="" />
            <img className="img-fluid u-logo-mobile" src="/assets/images/publisher-rocket-blue.png" width="100" alt="" />
          </NavLink>
        </div>

        <div className="u-header-right">
          <div className="dropdown mr-4">
            {/* <Link className="u-sidebar-nav-menu__link" to="/licenses">
                            <i className="fa fa-cubes u-sidebar-nav-menu__item-icon"></i>
                            <span className="u-sidebar-nav-menu__item-title">License</span>
                        </Link> */}
          </div>

          <div className="dropdown mr-4" style={{ display: "flex", flexDirection: "row" }}>
            <NavLink to={`${basePath}`} activeStyle={activeLinkStyle} className="u-sidebar-nav-menu__link" exact>
              <span className="u-sidebar-nav-menu__item-title" style={{fontWeight:"bold"}}>License List</span>
            </NavLink>

            <NavLink to={`${basePath}/activities`} activeStyle={activeLinkStyle} className="u-sidebar-nav-menu__link">
              <span className="u-sidebar-nav-menu__item-title" style={{fontWeight:"bold"}}>Activity Logs</span>
            </NavLink>

            <span style={{ cursor: "pointer" }} className="u-sidebar-nav-menu__link" onClick={logoutUserAfterConfirm}>
              {/* <i className="fa fa-sign-out u-sidebar-nav-menu__item-icon"></i> */}
              <span className="u-sidebar-nav-menu__item-title" style={{fontWeight:"bold"}}>Logout</span>
            </span>
          </div>
        </div>
      </header>
    </Fragment>
  );
}
