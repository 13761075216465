import React from "react";
import { Link, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


const getRouteList = (routes, isSideBar = false) => {
    let links = [];
    for (const key in routes) {
        if (routes.hasOwnProperty(key)) {
            const route = routes[key];
            route.map((x) => {
                if (isSideBar == true) {
                    if (x.isSideBar) {
                        links.push(x);
                    }
                } else {
                    links.push(x);
                }
            });
        }
    }

    return links;
};

export const createSideBarRouterLink = (routes) => {
    const routeList = getRouteList(routes, true);
    const reactLinks = routeList.map((x, i) => (
        <Link
            key={i}
            className="list-group-item list-group-item-action"
            to={x.path}
        >
            {x.label}
        </Link>
    ));
    return reactLinks;
};

export const createSwitchRoutes = (routes) => {
    const routeList = getRouteList(routes, false);
    const switchRoutes = routeList.map(({ path, component: C, exact }, i) => {
        // console.log("{path,component:C, exact}", { path, component: C, exact });
        return (
            <Route
                key={i}
                path={path}
                exact={exact}
                render={(props) => <C {...props} />}
            />
        );

        // return <Route key={i} path={path} component={C} />
    });
    // console.log(switchRoutes);

    return switchRoutes;
};

export const capitalizeText = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}


export const handleSentenceCase = (dataKey) => {

    return dataKey.split("_").map(x => capitalizeText(x)).join(" ")
}



export const serviceResponse = function (success = false, data = null, msg = "") {

    return { success, msg, data }
}

export const failedHttpResponse = function (data = null, msg = "") {

    return serviceResponse(false, data, msg)
}

export const successHttpResponse = function (data = null, msg = "") {

    return serviceResponse(true, data, msg)
}



const showToast = (message, success) => {

    const type = success ? "success" : "error"
    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
    });
}


export default {
    createSideBarRouterLink,
    createSwitchRoutes,
    serviceResponse,
    failedHttpResponse,
    successHttpResponse,
    handleSentenceCase,
    capitalizeText,
    showToast
};
