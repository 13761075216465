import axios from "axios";

import * as helperService from "../services/helper.service"
import configs from "../../config";
import errorConfig from "../../../config/error.config";
import { OWNER_LOCALSTORAGE_KEYS } from "../../../modules/license-management/services/owner.service";
import { logoutUser } from "./user.service";

export default class HttpClient {

    resourceUrl
    axiosClient = null
    headers = null
    axiosCancelToken = null
    constructor(resourceBaseName) {

        this.headers = {
            "Content-type": "application/json",
        }

        const injectToken = localStorage.getItem(OWNER_LOCALSTORAGE_KEYS.token);
        if (injectToken) {
            this.headers['Authorization'] = `Bearer ${injectToken}`
        }

        this.axiosClient = axios.create({
            baseURL: configs.API_BASE_URL,
            headers: this.headers
        });

        this.resourceUrl = `/${resourceBaseName}`

    }

    get axios() {
        return this.axiosClient
    }

    async getRequest(urlPath, cancelToken) {
        try {
            const { data } = await this.axiosClient.get(urlPath, {
                ...(cancelToken && { cancelToken: cancelToken }),
            });
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse(null, error?.message);
        }
    }

    async postRequest(urlPath, formData) {
        try {
            const { data } = await this.axiosClient.post(urlPath, formData);
            // console.log(`Data ->`, data);
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse("Oops something went wrong")
        }
    }

    async get(id) {

        try {
            const { data } = await this.axiosClient.get(`${this.resourceUrl}/${id}`);
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse()
        }
    };

    async create(data) {

        try {
            const { data } = await this.axiosClient.post(`${this.resourceUrl}`, data);
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse()
        }
    };

    async update(id, data) {

        try {
            const { data } = await this.axiosClient.put(`${this.resourceUrl}/${id}`, data);
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse()
        }
    };

    async remove(id) {


        try {
            const { data } = await this.axiosClient.delete(`${this.resourceUrl}/${id}`);
            return data
        } catch (error) {
            this.handleJwtExpiration(error);
            return helperService.failedHttpResponse()
        }
    };

    /**
     * Cancel axios token if it exists
     */
    cancelExistingAxiosToken () {
        if (this.axiosCancelToken) {
            this.axiosCancelToken.cancel(errorConfig.msg.cancelledToken);
        }

        this.axiosCancelToken = axios.CancelToken.source();
    }

    handleJwtExpiration (error) {
        if (error?.response?.data?.msg === errorConfig.msg.jwtExpired) {
            logoutUser();
        }
    }
}
