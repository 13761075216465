import HttpClient from "../../../core/commons/services/http-client.service";

export class LicenseService extends HttpClient {
  constructor() {
    super("licenses");
  }

  async getAll(paginate, sortColumn, sortOrder, query, type) {
    this.cancelExistingAxiosToken();

    let { page, perPage } = paginate
    const sortQuery = sortColumn && sortOrder ? `&sort_column=${sortColumn}&sort_order=${sortOrder}` : '';
    const searchQuery = query && type ? `&query=${query}&type=${type}` : '';

    return await this.getRequest(`${this.resourceUrl}?page=${page}&perPage=${perPage}${sortQuery}${searchQuery}`,
        this.axiosCancelToken?.token);
  }

  async search(query, type, page = 1, perPage = 30) {
    const urlPath = `${this.resourceUrl}/search?query=${query}&type=${type}&page=${page}&perPage=${perPage}`;
    return await this.getRequest(urlPath);
  }

  async generateLicense() {
    const urlPath = `${this.resourceUrl}/generate`;
    return await this.getRequest(urlPath);
  }

  async decryptLicense(license_key_hash) {
    const urlPath = `${this.resourceUrl}/hash/${license_key_hash}/decrypt`;
    return await this.getRequest(urlPath);
  }

  async addLicenseInfo(formData) {
    console.log(`Await -> data`, formData);
    const urlPath = `${this.resourceUrl}/add`;
    return await this.postRequest(urlPath, formData);
  }

  async updateLicenseInfo(formData) {
    console.log(`Await -> data`, formData);
    const { id, ...updateData } = formData;
    const urlPath = `${this.resourceUrl}/${id}/update`;
    return await this.postRequest(urlPath, updateData);
  }
}

export const licenseService = new LicenseService();
