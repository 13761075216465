import React, { Component, Fragment } from "react";
import { licenseService } from "../services/license.service";
import Spinner from "react-bootstrap/Spinner";
import { Form } from "react-bootstrap";

import moment from "moment";

import { userActivationService } from "../services/activation-user.service";
import helperService from "../../../core/commons/services/helper.service";
import ModalComponent from "../../../core/commons/components/modal.component";
import Chart from "../../../core/commons/components/Chart";
import {licenseUsageLogService} from "../services/license-usage-log.service";
import sortConfig from "../../../config/sort.config";
import colorConfig from "../../../config/color.config";

const colors = Object.values(colorConfig);

export default class LicenseAddEditForm extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        licensee_first_name: "",
        licensee_last_name: "",
        product_id: "3",
        order_id: "000000000",
        invoice_id: "0000000",
        status: "1",
        email: "",
        license_key_hash: "",
        note: "Test License",
        device_limit: 2,
        isLoading: false,
      },
      selectedRow: null,
      action: "add",
      isHashDecrypted: true,
      isNewLicense: false,
      actionButtonText: "Add License",
      isEditing: true,
      isShowBtn: true,
      errorText: "",
      formErrors: {},
      isEdited: false,
      fullView: false,
      showChart: false,
      chartData: null,
    };
  }

  async componentDidUpdate(prevProps) {
    console.log(`Prev Propos ->`, prevProps);
    console.log(`Current Propos ->`, this.props);

    // alert("Component Updating")

    if (prevProps.action != this.props.action) {
      let { action, selectedRow } = this.props;
      const licenseInfo = await licenseService.get(selectedRow.id);
      console.log(`License info ->`, licenseInfo);

      selectedRow = { ...selectedRow, ...licenseInfo.data };
      this.setState({ action, selectedRow });

      setTimeout(() => {
        if (action == "edit") {
          this.handleFormPopulate();
        }
      }, 500);
    }
  }

  async componentDidMount() {
    // const {formFields} =
    await this.generateLicenseKey(false);
    let { action, selectedRow, fullView } = this.props;

    if (selectedRow != null) {
      const licenseInfo = await licenseService.get(selectedRow.id);
      console.log(`License info ->`, licenseInfo);

      selectedRow = { ...selectedRow, ...licenseInfo.data };
    }

    const isShowBtn = action == "add" ? true : false;

    this.setState({ action, isShowBtn, selectedRow, fullView: fullView || false });

    setTimeout(async () => {
      if (action == "edit") {
        // this.setState({})

        this.handleFormPopulate();
      }
    }, 500);
  }

  updateFormDataState = (key, value) => {
    const formData = this.state.formData;
    formData[key] = value;
    this.setState({ formData });
  };

  handleValueChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target);
    console.log(`${name} : ->`, value);
    this.updateFormDataState(name, value);
  };

  updateErrorText = (hasError = false) => {
    let errorText = null;

    if (hasError) {
      errorText = "Ooops something went wrong";
    }

    setTimeout(() => this.setState({ errorText }), 500);
  };

  handleFormValidation = (formData) => {
    const formErrors = {};
    let hasError = false;
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const element = formData[key];

        if (key == "note" || element == null) {
          return;
        }

        console.log(`Ellement-<`, element);

        if (element.toString().trim().length <= 0) {
          const field = helperService.handleSentenceCase(key);
          formErrors[key] = `${field} is required`;
          hasError = true;
        }
      }
    }

    console.log(`formErrors ->`, formErrors);

    this.setState({ formErrors });
    return hasError;
  };

  handleFormAction = async () => {
    try {
      const { formData, isNewLicense, isHashDecrypted, action, isLoading } = this.state;

      const hasFormError = this.handleFormValidation(formData);

      if (hasFormError) {
        return;
      }

      const data = this.state.formData;

      const { handleDone } = this.props;

      this.setState({ isLoading: true, errorText: "" });

      let actionRequest = null;
      if (action == "add") {
        actionRequest = await licenseService.addLicenseInfo(data);
        console.log(`LicensRequest ->`, actionRequest);
        if (actionRequest.success) {
          handleDone({ type: "reload" });
          helperService.showToast(`License succcessfully added`, true);
        } else {
          helperService.showToast(`Oops something went wrong`, false);
        }
      } else if (action == "edit") {
        const updateData = { ...formData, isNewLicense, isHashDecrypted };
        actionRequest = await licenseService.updateLicenseInfo(updateData);

        if (!actionRequest.success) {
          this.setState({ isLoading: false });
          this.updateErrorText(true);
          return;
        } else {
          this.setState({ isLoading: false });
          helperService.showToast(`License succcessfully updated`, true);
          this.handleIsEditting();
          updateData["license_key"] = updateData["license_key_hash"];
          updateData["name"] = `${updateData["licensee_first_name"]} ${updateData["licensee_last_name"]}`;
          console.log(`updateData ---->`, updateData);
          handleDone({ type: "refresh", data: updateData });
        }
      }
      // handleDone({ type: "reload" })

      // TODO: Close Modal
      // console.log(this.state.formData);
    } catch (error) {
      console.log(error);
      this.updateErrorText(true);
    }
  };

  handleFormPopulate = () => {
    let { formData, selectedRow } = this.state;
    formData = { ...formData, ...selectedRow };
    console.log(`Updated new Form ->`, formData);
    const actionButtonText = "Save License";
    this.setState({ formData, actionButtonText, isEditing: false });
  };

  generateLicenseKey = async (addToState) => {
    const licenseKey = await licenseService.generateLicense();
    this.updateFormDataState("license_key_hash", licenseKey);
    if (addToState) {
      this.setState({ isNewLicense: true });
    }
  };

  decryptLicense = async () => {
    // const { formData, isHashDecrypted } = this.state
    // if (isHashDecrypted) {
    //     return
    // }
    // const { license_key_hash } = formData
    // console.log(`Decrpyt Licence hash ->`, license_key_hash);
    // // this.setState({ isHashDecrypted: !isHashDecrypted })
    // const licenseKey = await licenseService.decryptLicense(license_key_hash)
    // this.updateFormDataState("license_key_hash", licenseKey)
  };

  handleIsEditting = () => {
    const { isEditing, isShowBtn } = this.state;
    this.setState({ isEditing: !isEditing, isShowBtn: !isShowBtn });
  };

  handleViewLicense = (row) => {
    this.props.history.push(`/licenses/activities`, { id: this.state.selectedRow.id });
  };

  handleDeactivate = async (data, index) => {
    const { selectedRow } = this.state;
    const { handleDone } = this.props;
    let activationItems = selectedRow.activation_list;

    console.log(`Selected Item ->`, activationItems[index]);

    activationItems[index]["active"] ^= 1;
    activationItems[index]["statusChanging"] = true;
    this.setState({ selectedRow });
    try {
      const updateLicense = await userActivationService.updateUserActivation(activationItems[index]);

      if (!updateLicense.success) {
        activationItems[index]["active"] ^= 1;
      }

      delete activationItems[index]["statusChanging"];

      this.setState({ selectedRow });
      handleDone({ type: "refresh", data: selectedRow });
    } catch (error) {
      console.error('handleDeactivate err: ', error);
    }
  };

  handleDeleteDevice = async (data, index) => {
    let { selectedRow } = this.state;
    const { handleDone } = this.props;
    let activationItems = selectedRow.activation_list;

    console.log(`Selected Item ->`, activationItems[index]);

    const confirmation = window.confirm(
      `Are you  sure you want to delete the activation for ${activationItems[index]["mac_address"]}`
    );

    if (!confirmation) {
      return;
    }

    // item[index]['active'] ^= 1
    activationItems[index]["statusChanging"] = true;
    this.setState({ selectedRow });
    let isEdited = false;
    try {
      const deleteLicense = await userActivationService.deleteUserActivation(activationItems[index]["id"]);

      if (deleteLicense.success) {
        // item[index]['active'] ^= 1
        helperService.showToast(`Activation Deleted`, true);
        // activationItems.splice(index, 0)
        activationItems = activationItems.filter((x) => x.id != activationItems[index]["id"]);
        selectedRow["activation_list"] = activationItems;
        selectedRow["activation_count"] = activationItems.length;

        console.log(`Selected Row -> activation delete- >`, selectedRow);
        // delete activationItems[index]['statusChanging']
        isEdited = true;
        setTimeout(() => {
          this.setState({ selectedRow });
          handleDone({ type: "refresh", data: selectedRow });
        }, 500);
      } else {
        helperService.showToast(`Failed to delete activation`, false);
        delete activationItems[index]["statusChanging"];
      }
    } catch (error) {
      console.log(error);
      helperService.showToast(`Oops something went wrong`, false);
    }
  };

  renderActivationList = (activationList) => {
    console.log(`Loaded activation List`);
    // console.log(activationList);
    // activationList = undefined
    if (!activationList || activationList.length <= 0) {
      console.log(`Not inside activation list`);
      return (
        <tr colspan="3">
          <td> No records found</td>
        </tr>
      );
    }

    const rows = activationList.map((x, i) => {
      // console.log(`Activation Item -> index -> item =>`, i, x);
      const row = (
        <tr key={i}>
          <td>{x.mac_address}</td>
          <td>{moment(x.created_at).format("MM/DD/YYYY")}</td>
          <td>{moment(x.last_use_at).format("MM/DD/YYYY")}</td>
          <td>
            {/*{x.active == 1 ? "Active" : "Inactive"}*/}
            {x["statusChanging"] ? (
                <Spinner animation="border" size="sm" />
            ) : (
                <Form.Check
                    key={i}
                    checked={x.active === 1 ? true : false}
                    onChange={() => this.handleDeactivate(x, i)}
                    type="switch"
                    id={`custom-switch-${i}`}
                    label={x.active === 1 ? "Active" : "Inactive"}
                />
            )}
          </td>
          <td>
            {x["statusChanging"] ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <img
                src={"/assets/images/delete-icon.png"}
                key={i}
                onClick={() => this.handleDeleteDevice(x, i)}
                style={{ height: "1.8rem" }}
              />
            )}
          </td>
        </tr>
      );

      return row;
    });

    console.log(`This is the row laoded -<`, rows);

    return rows;
  };


  goToChartPage = () => {

  }

  handleChartOpen = async () => {
    await this.getLicenseActivityLogsGrouped();
    this.setState({ showChart: true });
  };

  handleChartClose = () => {
    this.setState({ showChart: false });
  };

  getLicenseActivityLogsGrouped = async () => {
    const licenseUsageLogs = await licenseUsageLogService.getLicenseUsageLogs({
      licenseId: this.props.selectedRow.id,
      page: 1,
      perPage: 60,
      sortColumn: 'created_date',
      sortOrder: sortConfig.sortOrder.desc,
      groupBy: 'created_date, country',
    });

    console.log('licenseUsageLogs: ', licenseUsageLogs);

    const days = [];
    const countries = [];
    const datasets = [];

    licenseUsageLogs.forEach((usageLog) => {
      if (!days.includes(usageLog.created_date)) {
        days.push(usageLog.created_date);
      }

      if (!countries.includes(usageLog.country)) {
        countries.push(usageLog.country);
      }
    });

    countries.forEach((country, index) => {
      const arr = [];

      days.forEach((day) => {
        const val = licenseUsageLogs.find((usageLog) => usageLog.country === country && usageLog.created_date === day);
        arr.push(val ? val.total_request_count : 0);
      });

      datasets.push({
        label: country,
        data: arr,
        backgroundColor: colors[index] ? colors[index] : 'lightblue',
      })
    });

    this.setState({
      chartData: {
        labels: days,
        datasets,
      },
    });
  };

  render() {
    const {
      formData,
      action,
      actionButtonText,
      isLoading,
      isEditing,
      isShowBtn,
      selectedRow,
      errorText,
      formErrors,
      fullView,
    } = this.state;
    const {
      licensee_first_name,
      licensee_last_name,
      product_id,
      order_id,
      invoice_id,
      status,
      email,
      license_key_hash,
      note,
      device_limit,
    } = formData;

    return (
      <>
      {this.state.showChart ? (
          <>
            <div className="row">
              <div className="col-md-6">
                <button className="btn btn-sm btn-primary" onClick={this.handleChartClose}>
                  Back
                </button>
              </div>
            </div>
            <Chart
                options={{
                  title: 'Usage History Chart',
                  xStacked: true,
                  yStacked: true,
                  xGrid: false,
                }}
                data={this.state.chartData}
            />
          </>
      ) : (
      <Fragment>
        {action == "edit" ? (
          <div className="row">
            <div className="col-md-6">
              <h3>License Info</h3>
            </div>
            <div className="col-md-6 text-right">
              <button className="btn btn-primary" onClick={this.handleIsEditting}>
                {!isEditing ? "Edit" : "Cancel"}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <label htmlFor="">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                    value={licensee_first_name}
                    name="licensee_first_name"
                    placeholder="First name"
                  />
                  <p className="text-danger form-error-text">
                    {formErrors["licensee_first_name"] ? formErrors["licensee_first_name"] : ""}
                  </p>
                </div>
                <div className="col">
                  <label htmlFor="">Last Name</label>
                  <input
                    type="text"
                    name="licensee_last_name"
                    value={licensee_last_name}
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                    placeholder="Last name"
                  />
                  <p className="text-danger form-error-text">
                    {formErrors["licensee_last_name"] ? formErrors["licensee_last_name"] : ""}
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                    placeholder="Email"
                  />
                  <p className="text-danger form-error-text">{formErrors["email"] ? formErrors["email"] : ""}</p>
                </div>
              </div>

              <div className="form-row">
                {/* <div className="col form-group">
                            <label htmlFor="">Product</label>
                            <input type="text" value={product_id} name="product_id" className={(isEditing) ? "form-control": "form-control-plaintext"} readOnly={!isEditing}  onChange={this.handleValueChange} />
                        </div> */}

                <div className="col form-group">
                  <label htmlFor="">Device Limit</label>
                  <input
                    type="text"
                    value={device_limit}
                    name="device_limit"
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                  />
                  <p className="text-danger form-error-text">
                    {formErrors["device_limit"] ? formErrors["device_limit"] : ""}
                  </p>
                </div>

                <div className="col form-group">
                  <label htmlFor="">Invoice Id</label>
                  <input
                    type="text"
                    value={invoice_id}
                    name="invoice_id"
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                  />
                  <p className="text-danger form-error-text">
                    {formErrors["invoice_id"] ? formErrors["invoice_id"] : ""}
                  </p>
                </div>

                {/* <div className="col form-group">
                                    <label htmlFor="">Order</label>
                                    <input type="text" value={order_id} name="order_id" className="form-control" disabled={!isEditing} readOnly={!isEditing} onChange={this.handleValueChange} />
                                </div> */}

                {action == "edit" && selectedRow != null ? (
                  <div className="col form-group">
                    <label htmlFor="">Purchase Date</label>
                    <input
                      type="text"
                      value={moment(selectedRow.created_at).format("MM/DD/YYYY")}
                      name="created_at"
                      className="form-control"
                      disabled={true}
                      readOnly={true}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="col form-group">
                  <label htmlFor="">Status</label>
                  <select
                    disabled={!isEditing}
                    name="status"
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                    onChange={this.handleValueChange}
                    value={status}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group  w-100">
                  <label htmlFor="">License Key</label>

                  {action == "add" ? (
                    <div className="d-flex w-100 align-items-center">
                      <input
                        type="text"
                        disabled={true}
                        name="license_key_hash"
                        value={license_key_hash}
                        className="form-control mr-3"
                        onChange={this.handleValueChange}
                        placeholder="License Key"
                      />
                      <button type="button" className="btn btn-primary" onClick={() => this.generateLicenseKey(true)}>
                        Generate Key
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex w-100 align-items-center">
                      <span className=" w-100 form-icon-wrapper">
                        <span className="form-icon form-icon--right" onClick={this.decryptLicense}>
                          <i className="fa fa-user-circle form-icon__item"></i>
                        </span>
                        <input
                          type="text"
                          disabled={true}
                          name="license_key_hash"
                          value={license_key_hash}
                          className="form-control"
                          disabled={!isEditing}
                          readOnly={!isEditing}
                          onChange={this.handleValueChange}
                          placeholder="License Key"
                        />
                      </span>
                      {isEditing ? (
                        <button
                          type="button"
                          className="ml-3 btn btn-primary"
                          onClick={() => this.generateLicenseKey(true)}
                        >
                          Generate New Key
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="">Note</label>
                  <input
                    type="text"
                    name="note"
                    onChange={this.handleValueChange}
                    value={note}
                    placeholder="Note"
                    className="form-control"
                    disabled={!isEditing}
                    readOnly={!isEditing}
                  />
                  <p className="text-danger form-error-text">{formErrors["note"] ? formErrors["note"] : ""}</p>
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="form-group w-100 text-right">
                  <p className="text-danger">{errorText}</p>
                  {isShowBtn ? (
                    <button
                      disabled={isLoading}
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleFormAction}
                    >
                      {isLoading ? <Spinner className="mr-2" animation="border" size="sm" /> : ""}

                      {actionButtonText}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        {action == "edit" && selectedRow != null ? (
          <div className="row">
            <div className="col-md-12">
              <h3>Activation List</h3>
            </div>
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>Mac Address</th>
                    <th>Activation Time</th>
                    <th>Last Use</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>{this.renderActivationList(selectedRow.activation_list)}</tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )}

        {!fullView && selectedRow != null ? (
          <div className="row">
            <div className="col-md-6">
              <button className="btn btn-primary" onClick={() => this.handleViewLicense()}>
                View activity log
              </button>

              <button className="btn btn-primary ml-1" onClick={this.handleChartOpen}>
                View usage history chart
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
      )}
      </>
    );
  }
}
