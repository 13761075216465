import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Chart = ({options, data}) => {
    const { title, xGrid, yGrid, xStacked, yStacked } = options || {};

    const chartOptions = {
        plugins: {
            title: {
                display: true,
                text: title,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: xStacked ?? false,
                grid: {
                    display: xGrid ?? true,
                },
            },
            y: {
                stacked: yStacked ?? false,
                grid: {
                    display: yGrid ?? true,
                },
            },
        },
    };

    return (
        <Bar options={chartOptions} data={data} />
    )
}

export default Chart;
