import React, { Component, Fragment } from "react";
import { licenseService } from "../../services/license.service";
import DataTable from "react-data-table-component";
import helperService from "../../../../core/commons/services/helper.service";
import LicenseSearch from "../../components/license-search.component";
import LicenseAddEditForm from "../../components/license-add-edit-form.component";
import { act } from "react-dom/test-utils";
import ModalComponent from "../../../../core/commons/components/modal.component";
import KebabMenuComponent from "../../../../core/commons/components/kebab-menu.component";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import sortConfig from "../../../../config/sort.config";
import errorConfig from '../../../../config/error.config';
import deviceConfig from '../../../../config/device.config';

export default class LicenseListPage extends Component {
  constructor() {
    super();
    this.state = {
      licenseList: [],
      dataTableColumns: [],
      isLoadedData: false,
      selectedRow: null,
      action: "add",
      isShowModal: false,
      menuItems: [],
      dataTableLoading: true,
      totalRows: 0,
      perPage: 30,
      searching: {
        query: null,
        type: null,
      },
      defaultPage: 30,
      sortColumn: 'request_count_30_days',
      sortOrder: sortConfig.sortOrder.desc,
    };
  }

  handleLicenseEdit = async (row) => {
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        const element = row[key];
        if (element == null) {
          row[key] = "";
        }
      }
    }

    this.setState({ action: "edit", selectedRow: row });
    this.handleModalOpen();
  };

  handleLicenseAdd = async () => {
    this.setState({ action: "add", selectedRow: null });
    this.handleModalOpen();
  };

  handleSort = async (column, sortDirection) => {
    this.setState({
      sortColumn: column.sortField,
      sortOrder: sortDirection,
    });

    await this.loadLicenseInfo(null, null, column.sortField, sortDirection, this.state.searching.query,
        this.state.searching.type);
  };

  async componentDidMount() {
    const licenses = await this.loadLicenseInfo(null, null, this.state.sortColumn,
        this.state.sortOrder);

    if (licenses.success) {
      const licenseList = licenses.data.data;
      // console.log(licenseList);

      if (licenseList.length > 0) {
        const omitColumns = [
          "id",
          "product_id",
          "license_key_hash",
          "created_at",
          "updated_at",
          "licensee_first_name",
          "licensee_last_name",
          "invoice_id",
          "note",
        ];
        const growColumn = {
          note: 2,
          email: 3,
          name: 2,
          license_key: 2,
          created_at: 1.5,
        };
        const formatColumn = ["status"];
        const columns = [
          "email",
          "name",
          "invoice_id",
          "license_key",
          "created_at",
          "activation_count",
          "device_types",
          "note",
          "request_count_",
          "status",
        ];
        let requestCountArr = [];
        let deviceTypes = [];

        let dataTableColumns = columns.map((dataKey) => {
          if (dataKey == "created_at") {
            return {
              name: "Purchase Date",
              selector: dataKey,
              // allowOverflow: true,
              wrap: true,
              grow: growColumn[dataKey] ? growColumn[dataKey] : 1,
              // omit: (omitColumns.includes(dataKey)) ? true : false,
              format: (row) => moment(row.created_at).format("MM/DD/YYYY"),
            };
          } else if (dataKey.includes("request_count_")) {
            const daysRange = [3, 7, 30];

            requestCountArr = daysRange.map((day) => {
              return {
                name: `Request Count ${day} Days`,
                selector: `${dataKey}${day}_days`,
                wrap: true,
                grow: growColumn[dataKey] ? growColumn[dataKey] : 1,
                sortable: true,
                sortField: `request_count_${day}_days`,
              };
            });
          } else if (dataKey === "device_types") {
            const getDeviceTypes = (rowDetails) => {
              if (!rowDetails.device_types) {
                return 'N/A';
              }

              const devices = rowDetails.device_types.split(',');
              const deviceConfigData = Object.values(deviceConfig);
              const deviceNames = [];

              devices.forEach((device) => {
                const foundDevice = deviceConfigData.find((el) => el.abbreviation === device);
                if (foundDevice) {
                  deviceNames.push(foundDevice.name);
                }
              })

              return deviceNames.join(', ');
            }

            deviceTypes.push({
              name: helperService.handleSentenceCase(dataKey),
              selector: dataKey,
              wrap: true,
              grow: 2,
              format: (row) => getDeviceTypes(row),
            });
          } else {
            return {
              name: helperService.handleSentenceCase(dataKey),
              selector: dataKey,
              // allowOverflow: true,
              wrap: true,
              grow: growColumn[dataKey] ? growColumn[dataKey] : 1,
              omit: omitColumns.includes(dataKey) ? true : false,
              format: formatColumn.includes(dataKey) ? (row) => (row.status ? "Active" : "Inactive") : null,
            };
          }
        });

        dataTableColumns = dataTableColumns.filter((col) => col);
        dataTableColumns.push(...requestCountArr, ...deviceTypes);

        dataTableColumns.push({
          cell: (row) => (
            <button
              className="btn btn-outline-primary p-1"
              style={{ width: "80px" }}
              onClick={() => this.handleLicenseEdit(row)}
            >
              View
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        });

        this.setState({ licenseList, isLoadedData: true, dataTableColumns, isLoadedData: true });
      }
    }
  }

  reloadLicenseList = async () => {
    const licenses = await licenseService.getAll();
    this.setState({ licenseList: licenses.data });
  };

  async loadLicenseInfo(page, perPage, column, sortDirection, query, type) {
    page = page ? page : 1;
    perPage = perPage ? perPage : this.state.perPage;

    this.setState({ dataTableLoading: true, perPage });

    let licenseRequest = await licenseService.getAll({ page, perPage }, column, sortDirection, query, type);

    if (licenseRequest.success) {
      const { data, total, pages } = licenseRequest.data;
      this.setState({ totalRows: total, licenseList: data, dataTableLoading: false });
    } else {
      if (licenseRequest.msg !== errorConfig.msg.cancelledToken) {
        this.setState({ totalRows: 0, licenseList: [], dataTableLoading: false });
      }
    }

    return licenseRequest;
  }

  handlePageChange = async (page) => {
    await this.loadLicenseInfo(page, this.state.perPage, this.state.sortColumn, this.state.sortOrder,
        this.state.searching.query, this.state.searching.type);
  }

  handlePerRowsChange = async (perPage, page) => await this.loadLicenseInfo(page, perPage,
      this.state.sortColumn, this.state.sortOrder, this.state.searching.query, this.state.searching.type);

  handleSearch = async ({ query, type, perPage, page }) => {
    if (query.length <= 0) {
      this.setState({
        searching: {
          query: null,
          type: null,
        },
      });
      await this.loadLicenseInfo(page, perPage, this.state.sortColumn, this.state.sortOrder);
    } else {
      const searching = { query, type };
      this.setState({ searching });

      await this.loadLicenseInfo(page, perPage, this.state.sortColumn,
          this.state.sortOrder, query, type);
    }
  };

  handleModalClose = () => {
    this.setState({ isShowModal: false });
  };

  handleActionDone = async ({ action, type, data } = {}) => {
    console.log(`{ action, type, data } =>`, { action, type, data });

    if (type == "reload") {
      this.setState({ isShowModal: false }, () => {
        this.loadLicenseInfo(null, null, this.state.sortColumn, this.state.sortOrder);
      });
    } else if (type == "refresh") {
      const { licenseList } = this.state;
      console.log(`handleActionDone --> data -->`, data);
      const selectedIndex = licenseList.findIndex((x) => x.id == data.id);

      if (selectedIndex == -1) {
        helperService.showToast("Oops something went wrong", false);
        return;
      }

      const freshLicenseList = JSON.parse(JSON.stringify(licenseList));
      freshLicenseList[selectedIndex] = { ...freshLicenseList[selectedIndex], ...data };
      this.setState({ licenseList: freshLicenseList });
    }
  };

  handleModalOpen = (action) => {
    this.setState({ isShowModal: true });
  };

  render() {
    const {
      isLoadedData,
      dataTableColumns,
      licenseList,
      action,
      selectedRow,
      isShowModal,
      dataTableLoading,
      totalRows,
      defaultPage,
      paginationResetDefaultPage,
      perPage,
    } = this.state;
    return (
      <Fragment>
        {/* For Modal */}

        <ModalComponent
          show={isShowModal}
          size="lg"
          heading={action == "add" ? "Ädd License" : "Manage License"}
          handleClose={{ action: () => this.handleModalClose(), name: "Close" }}
        >
          <LicenseAddEditForm
            history={this.props.history}
            handleDone={this.handleActionDone}
            action={action}
            selectedRow={selectedRow}
          />
        </ModalComponent>

        {/*  Start of Page */}

        <div className="card">
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-6 d-flex  align-items-center">
                <h1 className="mb-0">License List</h1>
              </div>
              <div className="col-md-6 text-right">
                <button className="btn btn-primary" onClick={this.handleLicenseAdd}>
                  Add License
                </button>
              </div>
            </div>

            {isLoadedData ? (
              <Fragment>
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-md-5 text-right mb-4">
                    <LicenseSearch searchHandler={this.handleSearch} />
                  </div>
                </div>
                <DataTable
                  title=""
                  columns={dataTableColumns}
                  data={licenseList}
                  progressPending={dataTableLoading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  noHeader
                  page
                  paginationPerPage={perPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  defaultSortField="request_count_30_days"
                  defaultSortAsc={false}
                  onSort={this.handleSort}
                  sortServer
                />
              </Fragment>
            ) : (
              <p>Loading Data..</p>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
