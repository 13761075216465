import React, { Component } from 'react'
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './modules/authentication/pages/login/LoginPage';
import RegisterPage from './modules/authentication/pages/register/RegisterPage';
import LICENSE_MANAGEMENT_ROUTES from './modules/license-management/license-management.routes';
import Layout from './core/Layouts/Layout';
import { ownerService } from './modules/license-management/services/owner.service';
import LicenseListPage from './modules/license-management/pages/license-list/LicenseListPage';
import PRODUCT_MANAGEMENT_ROUTES from './modules/product-mangagement/product-management.routes';


const APP_ROUTES = {
  licenseRoutes: LICENSE_MANAGEMENT_ROUTES,
  productRoutes: PRODUCT_MANAGEMENT_ROUTES,
  basicRoute: [
    {
      path: "/licenses",
      exact: true,
      component: LicenseListPage,
    }
  ]
}

export default class App extends Component {

  constructor() {
    super()
    const isLoggedIn = ownerService.isLoggedIn()
    this.state = {
      isLoggedIn: isLoggedIn
    }
  }

  async componentDidMount() {

    const isLoggedIn = ownerService.isLoggedIn()
    // console.log(`isLoggedIn ->`, isLoggedIn);
    this.setState({ isLoggedIn })

  }

  authenticationRoute() {
    return (
      <Switch>
        <Route path="/" exact component={() => <Redirect to="/login" />} />
        <Route path="/login" component={LoginPage} />
        <Route path="/super-secret-registration" component={RegisterPage} />
      </Switch>
    )
  }

  dashboardRoute() {
    return <Layout routes={APP_ROUTES} />
  }

  render() {
    const { isLoggedIn } = this.state
    return (
      <Router>
        {
          (isLoggedIn) ? (this.dashboardRoute()) : (this.authenticationRoute())
        }
      </Router>)
  }
}

// export default App;
