import HttpClient from "../../../core/commons/services/http-client.service";

export class UserActivationService extends HttpClient {

    constructor() {
        super("activations")
    }

    async updateUserActivation(formData) {
        console.log(`Await -> data`, formData);
        const { id, ...updateData } = formData
        const urlPath = `${this.resourceUrl}/${id}/update/status`
        return await this.postRequest(urlPath, updateData)
    }

    async deleteUserActivation(id) {
        const urlPath = `${this.resourceUrl}/${id}/status/delete`
        return await this.postRequest(urlPath)
    }

}

export const userActivationService = new UserActivationService()