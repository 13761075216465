import React, { Component, Fragment } from 'react'
import { productService } from '../../services/product.service'
import ProductAddEditForm from '../../components/product-add-edit-form.component'
import DataTable from 'react-data-table-component';
import helperService from '../../../../core/commons/services/helper.service';
import ModalComponent from '../../../../core/commons/components/modal.component';



export default class ProductsListPage extends Component {


    constructor() {
        super()
        this.state = {
            productList: [],
            isLoadedData: true,
            dataTableColumns: [],
            // isLoadedData: false,
            selectedRow: null,
            action: "add",
            isShowModal: false,
            menuItems: [],

        }
    }


    async componentDidMount() {
        const productRequest = await productService.getAll()
        console.log(productRequest);
        if (productRequest.success) {
            const productList = productRequest.data
            if (productList && productList.length > 0) {
                const omitColumns = []
                const growColumn = []
                const formatColumn = []
                const dataTableColumns = Object.keys(productList[0]).map(dataKey => {

                    return {
                        name: helperService.handleSentenceCase(dataKey),
                        selector: dataKey,
                        // allowOverflow: true,
                        // wrap: true,
                        grow: (growColumn.includes(dataKey)) ? 5 : 1,
                        omit: (omitColumns.includes(dataKey)) ? true : false,
                        format: (formatColumn.includes(dataKey) ? ((row) => (row.status) ? "Active" : "Inactive") : null)
                    }
                })

                dataTableColumns.push({
                    // cell: (row) =>  <KebabMenuComponent/> <button onClick={() => this.handleLicenseEdit(row)} >Edit</button>,
                    // cell: (row) => <KebabMenuComponent menuItems={[{ label: "Edit", action: () => console.log(row) }]} />,
                    cell: (row) => <button className='btn btn-outline-primary p-1' style={{ "width": "80px" }} onClick={() => this.handleProductEdit(row)} >Edit</button>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                })

                this.setState({ productList, isLoadedData: true, dataTableColumns, isLoadedData: true })
            }
        }

    }


    loadProducts = async () => {
        let productRequest = await productService.getAll()

        if (productRequest.success) {

            const productList = productRequest.data
            this.setState({ productList, isLoadedData: false })
        } else {
            alert(`Able to load data`)
        }

    }

    handleProductEdit = async (row) => {

        for (const key in row) {
            if (row.hasOwnProperty(key)) {
                const element = row[key];
                if (element == null) {
                    row[key] = ""
                }
            }
        }

        this.setState({ action: "edit", selectedRow: row })
        this.handleModalOpen()
    }

    handleProductAdd = async () => {

        this.setState({ action: "add", selectedRow: null })
        this.handleModalOpen()
    }


    handleModalClose = async (action) => {

        this.setState({ isShowModal: false })

        if (action == "refresh") {
            this.reloadLicenseList()
        }


    }

    handleModalOpen = (action) => {
        this.setState({ isShowModal: true })
    }



    render() {
        const { isLoadedData, dataTableColumns, productList, action, selectedRow, isShowModal } = this.state
        return (

            <Fragment>

                {/* For Modal */}

                <ModalComponent show={isShowModal}
                    size="lg"
                    heading={(action == "add" ? "Ädd License" : "Manage License")}
                    handleClose={{ action: this.handleModalClose, name: "Close" }}>
                    <ProductAddEditForm handleDone={this.handleModalClose} action={action} selectedRow={selectedRow} />
                </ModalComponent>

                {/*  Start of Page */}

                <div className="card">

                    <div className="card-body">

                        <div className="row mb-4">
                            <div className="d-flex align-items-center justify-content-between col-md-12">
                                <h1>Product List</h1>
                                <button className="btn btn-primary" onClick={this.handleProductAdd}>Add Product</button>
                            </div>
                        </div>


                        {
                            (isLoadedData) ? (
                                <Fragment>
                                    <div className="row">
                                        <div className="col-md-7"></div>
                                        <div className="col-md-5 text-right mb-4">
                                            {/* <LicenseSearch searchHandler={this.handleSearch} /> */}
                                        </div>
                                    </div>
                                    <DataTable
                                        title=""
                                        columns={dataTableColumns}
                                        data={productList}
                                        pagination={true}
                                        noHeader
                                    />
                                </Fragment>
                            ) : (
                                    <p>Loading Data..</p>
                                )
                        }
                    </div>
                </div>

            </Fragment >
        )
    }
}
