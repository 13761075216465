import React, { Component, Fragment, useEffect, useState } from "react";
import { licenseService } from "../../services/license.service";
import LicenseAddEditForm from "../../components/license-add-edit-form.component";
import { Form, Spinner } from "react-bootstrap";

import { userActivationService } from "../../services/activation-user.service";
import helperService from "../../../../core/commons/services/helper.service";
import DataTable from "react-data-table-component";
import moment from "moment";
import useFuseSearch from "../../../../core/commons/hooks/useFuseSearch";
import {licenseUsageLogService} from "../../services/license-usage-log.service";
import sortConfig  from '../../../../config/sort.config';
import deviceConfig  from '../../../../config/device.config';
import config from "../../../../config/config";

export default function ActivityListPage(props) {
  const [state, setState] = useState({
    isEdit: false,
    licenseInfo: null,
    dataTableColumns: [],
    activityLog: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [licenseId, setLicenseId] = useState(props.history.location.state?.id);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(60);
  const [totalRows, setTotalRows] = useState(60);
  const [sortColumn, setSortColumn] = useState('created_date');
  const [sortOrder, setSortOrder] = useState(sortConfig.sortOrder.desc);
  const [searchQuery, setSearchQuery] = useState('');
  const [calledApi, setCalledApi] = useState(false);

  const handlePageChange = async (pageNo) => {
    setPage(pageNo);
  }

  const handlePerRowsChange = async (dataPerPage, pageNo) => {
    setPage(pageNo);
    setPerPage(dataPerPage);
  }

  const handleSort = async (column, sortDirection) => {
    setSortColumn(column.sortField);
    setSortOrder(sortDirection);
  };

  const getLicenseActivityLog = async () => {
    try {
      // console.log('getLicenseActivityLog: ', page, perPage, sortColumn, sortOrder, searchQuery);
      setDataTableLoading(true);
      setCalledApi(true);

      const licenseUsageLogs = await licenseUsageLogService.getLicenseUsageLogs({
        licenseId,
        page,
        perPage,
        sortColumn,
        sortOrder,
        query: searchQuery,
      });

      setTotalRows(licenseUsageLogs.data.total ?? 0);
      setState((prevState) => ({ ...prevState, activityLog: licenseUsageLogs.data?.data}));
      setDataTableLoading(false);

      return licenseUsageLogs.data?.data;
    } catch (error) {
      return null;
    }
  };

  const onLoad = async () => {
    const licenseInfo = await getLicenseActivityLog();

    if (licenseInfo == null) {
      return;
    } else {
      const omitColumns = ['total', 'updated_at'];
      const growColumn = ['email'];
      const formatColumn = ["device_type"];
      let dataTableColumns = [];

      let activityLog = [];

      if (licenseInfo[0]) {
        dataTableColumns = Object.keys(licenseInfo[0]).map((dataKey) => {
          return {
            name: helperService.handleSentenceCase(dataKey),
            selector: dataKey,
            grow: growColumn.includes(dataKey) ? 2 : 1,
            omit: omitColumns.includes(dataKey) ? true : false,
            wrap: true,
            sortable: true,
            sortField: dataKey,
            format: formatColumn.includes(dataKey)
                ? (row) => {
                  if (dataKey === 'device_type') {
                    const device = Object.values(deviceConfig).find((el) => el.abbreviation === row.device_type);
                    return device?.name ?? config.notAvailable;
                  }
                }
                : null,
          };
        });
        activityLog = licenseInfo;
      }

      setState((state) => ({ ...state, dataTableColumns, activityLog }));
    }

    //  this.getLicenseInfo()
  };

  useEffect(() => {
    if (licenseId) {
      onLoad();
    }
  }, [licenseId]);

  useEffect(() => {
    if (state.dataTableColumns.length > 0) {
      getLicenseActivityLog();
    }
    else if (licenseId || searchQuery) {
      onLoad();
    }
  }, [page, perPage, sortColumn, sortOrder]);

  const handleQueryChange = (event) => {
    setLicenseId('');
    setSearchQuery(event.target.value);
  }

  const onSearch = () => {
    if (searchQuery.length > 0) {
      setPage(1);
      if (state.dataTableColumns.length <= 0) {
        onLoad();
      }
      else {
        getLicenseActivityLog();
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  }

  return (
    <Fragment>
      {/* <div className="my-4 mx-2 pointer">
          <a href="/" style={{ color: "black" }}>
            {"<< "} Back
          </a>
        </div> */}
      <div className="card">
        <div className="card-body">
          <div className="row my-4">
            <div className="col-md-6">
              <h1>Activity Log</h1>
            </div>

            <div className="col-md-6" style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
              <div style={{ width: "50%" }} className="input-group search-field">
                <span className=" w-100 form-icon-wrapper">
                  <span className="form-icon  form-icon--right" onClick={onSearch}>
                    <i className="fa fa-search form-icon__item"></i>
                  </span>
                  <input
                    type="text"
                    // value={searchQuery}
                    // onKeyUp={handleKeyUp}
                    // onKeyDown={handleKeyDown}
                    className="form-control"
                    type="search"
                    placeholder="Search by email…"
                    onChange={handleQueryChange}
                    onKeyDown={handleKeyDown}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="row">
              <div className="col-md-12">
                <DataTable
                  progressPending={dataTableLoading}
                  columns={state.dataTableColumns}
                  data={state.activityLog}
                  noDataComponent={calledApi ? 'There are no records to display' : 'Search an email to get activity logs'}
                  noHeader
                  page
                  pagination
                  paginationServer
                  paginationPerPage={perPage}
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[30, 40, 50, 60]}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  defaultSortField='created_date'
                  defaultSortAsc={false}
                  onSort={handleSort}
                  sortServer
                />
              </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
