export default {
    chromebook: {
        name: 'chromebook',
        abbreviation: 'C',
        androidAdvertisingIdLength: 36,
    },
    mac: {
        name: 'mac',
        abbreviation: 'M',
    },
    windows: {
        name: 'windows',
        abbreviation: 'W',
    },
}
