import helperService from "./helper.service";

const clearStorageAndGoToLogin = () => {
    localStorage.clear();
    window.location.href = "/login";
};

export const logoutUser = (requireConfirmation) => {
    if (requireConfirmation) {
        if (window.confirm("Are you sure you want to logout ?")) {
            clearStorageAndGoToLogin();
        }
    }
    else {
        helperService.showToast(`Session expired. Please login again.`, false);
        setTimeout(() => {
            clearStorageAndGoToLogin();
        }, 2000);
    }
};
