import ActivityListPage from "./pages/activity-list/ActivityList";
import LicenseListPage from "./pages/license-list/LicenseListPage";
import SingleLicenseItemPage from "./pages/single-license-item/SingleLicenseItemPage";

const BASE_PATH = "/licenses";

const LICENSE_MANAGEMENT_ROUTES = [
  {
    path: BASE_PATH,
    exact: true,
    component: LicenseListPage,
    isSideBar: true,
    label: "Licenses",
  },
  {
    path: `${BASE_PATH}/activities`,
    exact: true,
    component: ActivityListPage,
  },
/*  {
    path: `${BASE_PATH}/activities/:id`,
    exact: true,
    component: SingleLicenseItemPage,
  }, */
];

export default LICENSE_MANAGEMENT_ROUTES;
