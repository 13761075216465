import HttpClient from "../../../core/commons/services/http-client.service";

export class ProductService extends HttpClient {

    constructor() {
        super("products")
    }

    async updateProduct(formData) {
        console.log(`Await -> data`, formData);
        const { id, ...updateData } = formData
        const urlPath = `${this.resourceUrl}/${id}/update`
        return await this.postRequest(urlPath, updateData)
    }

}

export const productService = new ProductService()